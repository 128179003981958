// Next
import Image from 'next/image'
import Link from 'next/link'

// Components
import { Button } from '@/components/ui/button'

// External
import { ArrowRightIcon } from 'lucide-react'

export function HeroLanding() {
  return (
    <div className='relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 py-12 sm:py-16'>
      <div
        aria-hidden='true'
        className='absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96'
      />
      <div className='relative isolate overflow-hidden'>
        <div className='mx-auto max-w-7xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8'>
          <div className='px-6 lg:px-0 lg:pt-4'>
            <div className='mx-auto max-w-2xl'>
              <div className='max-w-lg'>
                <h1 className='mt-10 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl'>
                  Turn text into memes using AI
                </h1>
                <p className='mt-4 text-3xl font-semibold leading-8 text-gray-700'>
                  In 110+ Languages
                </p>
                <p className='mt-6 text-lg/8 text-gray-600'>
                  Your first 10 AI memes are on us. No Credit Card Required.
                </p>
                <div className='mt-8 flex items-center gap-x-6'>
                  <Button asChild size='lg'>
                    <Link href='/text-to-meme'>
                      <span>Start Now</span>
                      <ArrowRightIcon className='ml-2 h-4 w-4' />
                    </Link>
                  </Button>
                  <Button asChild variant='outline' size='lg'>
                    <Link
                      href='/meme-marketing'
                      className='text-sm font-semibold leading-6 text-gray-900'
                    >
                      Learn more
                    </Link>
                  </Button>
                </div>
                {/* TODO: Add this back in when app issues are sorted */}
                {/* <div className='mt-6 flex items-center space-x-4'>
                    <a href='#' className='inline-block'>
                      <img
                        src='/static/landing_pages/home/2ZU0YvvqyzUiQ8QPGwzVl471nfl_google-play-badge.png'
                        alt='Get it on Google Play'
                        className='h-12'
                      />
                    </a>
                    <a href='#' className='inline-block'>
                      <img
                        src='/static/landing_pages/home/2ZItXZPpM1vCnXFuOzLPeZZDLRT_Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
                        alt='Download on the App Store'
                        className='h-12'
                      />
                    </a>
                  </div> */}
              </div>
            </div>
          </div>
          <div className='mt-20 px-1 sm:mt-24 sm:px-0 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen'>
            <Image
              src='/static/landing-pages/home/hero.jpg'
              alt='Meme generation process'
              width={500}
              height={500}
              className='object-contain shadow-xl ring-1 ring-gray-400/10'
              priority
            />
          </div>
        </div>
        <div className='absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32' />
      </div>
    </div>
  )
}
