// React
import { useEffect, useState } from 'react'

// Next
import Head from 'next/head'
import { useRouter } from 'next/router'

// Components
import WhySupermeme from '@/components/website/WhySupermeme'
import HowItWorks from '@/components/website/HowItWorks'
import WhyYouNeedIt from '@/components/website/WhyYouNeedIt'
import WhoNeedsIt from '@/components/website/WhoNeedsIt'
import MemeAPICTA from '@/components/website/MemeAPICTA'
import FAQ from '@/components/website/FAQ'
import CTAComponent from '@/components/website/core/CTA'
import { HeroLanding } from '@/components/website/core/HeroLanding'
import { SimpleSearch } from '@/components/memes/simple-search'

// Utils
import { useSession } from '@/utils/auth/user-context'
import { getLandingStaticProps } from '@/utils/ui/landing/get-static-props'

// External
import { useTranslation } from 'next-i18next'

export default function HomePage() {
  const { t } = useTranslation([
    'routes/landing-index',
    'components/why-supermeme',
    'components/how-it-works',
    'components/why-you-need-it',
    'components/who-needs-it',
    'components/meme-api-cta',
    'components/cta',
    'components/hero-landing',
  ])

  const session = useSession()
  const router = useRouter()
  const [isNavigating, setIsNavigating] = useState(false)

  useEffect(() => {
    const handleNavigation = async () => {
      if (!!session && !isNavigating) {
        try {
          setIsNavigating(true)
          await router.replace('/text-to-meme')
        } catch (error) {
          console.error('Navigation error:', error)
          setIsNavigating(false)
        }
      }
    }

    handleNavigation()
  }, [session, router, isNavigating])

  return (
    <div>
      <Head>
        <title>{t('routes/landing-index:meta.title')}</title>
        <meta
          name='description'
          content={t('routes/landing-index:meta.description')}
        />
        <meta property='og:url' content='https://supermeme.ai' />
        <link rel='canonical' href='https://supermeme.ai' />
      </Head>

      <HeroLanding />

      <div className='mx-auto max-w-4xl space-y-8 py-12 sm:py-16'>
        <h2 className='text-center text-4xl font-bold'>
          {t('routes/landing-index:textSection.search.title')}
        </h2>
        <p className='text-center text-base leading-relaxed text-gray-600'>
          {t('routes/landing-index:textSection.search.description')}
        </p>
        <SimpleSearch />
      </div>

      {/* Why Supermeme section */}
      <WhySupermeme />

      {/* How it works section */}
      <HowItWorks />

      {/* ... any other sections ... */}
      <WhyYouNeedIt />

      {/* Who needs it section */}
      <WhoNeedsIt />

      {/* Meme API CTA section */}
      <MemeAPICTA />

      {/* FAQ section */}
      <FAQ
        faqs={
          t('routes/landing-index:faqs.items', { returnObjects: true }) as {
            question: string
            answer: string
          }[]
        }
      />

      {/* New section */}
      <div className='relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto max-w-2xl text-center'>
            <p className='text-base font-semibold leading-7 text-indigo-600'>
              {t('routes/landing-index:workflow.tagline')}
            </p>
            <h2 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
              {t('routes/landing-index:workflow.title')}
            </h2>
            <p className='mt-6 text-xl leading-8 text-gray-700'>
              {t('routes/landing-index:workflow.description')}
            </p>
          </div>

          <div className='mt-16 flex justify-center'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0'
                  >
                    {t('routes/landing-index:workflow.table.headers.feature')}
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-bold text-gray-900'
                  >
                    {t('routes/landing-index:workflow.table.headers.supermeme')}
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-bold text-gray-900'
                  >
                    {t('routes/landing-index:workflow.table.headers.others')}
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    {t('routes/landing-index:workflow.table.features.ai')}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    {t(
                      'routes/landing-index:workflow.table.features.templates'
                    )}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    {t(
                      'routes/landing-index:workflow.table.features.multilingual'
                    )}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    {t('routes/landing-index:workflow.table.features.api')}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    {t('routes/landing-index:workflow.table.features.export')}
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='mt-16 text-center'>
            <div className='mx-auto max-w-xl text-base leading-7 text-gray-700'>
              <p>{t('routes/landing-index:workflow.summary.first')}</p>
              <p className='mt-8'>
                {t('routes/landing-index:workflow.summary.second')}
              </p>
              <h2 className='mt-16 text-2xl font-bold tracking-tight text-gray-900'>
                {t('routes/landing-index:workflow.summary.cta')}
              </h2>
              <p className='mt-6'>
                {t('routes/landing-index:workflow.summary.noDesignSkills')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <CTAComponent />
      </div>
    </div>
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) =>
  getLandingStaticProps({
    locale,
    namespaces: [
      'common',
      'routes/landing-index',
      'components/why-supermeme',
      'components/how-it-works',
      'components/why-you-need-it',
      'components/who-needs-it',
      'components/meme-api-cta',
      'components/cta',
      'components/hero-landing',
    ],
  })
